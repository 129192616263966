var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.permissionsData.view_users
    ? _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { col: "12" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { staticClass: "card-header-with-button" },
                            [_vm._v("Users")]
                          ),
                          _c(
                            "CCol",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "CButton",
                                {
                                  attrs: { color: "success" },
                                  on: { click: _vm.addUserClick }
                                },
                                [_vm._v("Add User")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("CCardBody", [
                    _vm.showTable
                      ? _c(
                          "div",
                          [
                            _c("CDataTable", {
                              attrs: {
                                hover: "",
                                striped: "",
                                items: _vm.userData,
                                fields: _vm.fields,
                                "items-per-page": 15,
                                "clickable-rows": "",
                                "active-page": _vm.activePage,
                                pagination: {
                                  doubleArrows: false,
                                  align: "center"
                                }
                              },
                              on: {
                                "row-clicked": _vm.rowClicked,
                                "page-change": _vm.pageChange
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "status",
                                    fn: function(data) {
                                      return [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "CBadge",
                                              {
                                                attrs: {
                                                  color: _vm.getBadge(
                                                    data.item.status
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.status) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3517044016
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }